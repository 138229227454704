<template>
  <div>
    <a-spin :spinning="loading">
      <a-breadcrumb class="bread-crumb">
        <a-breadcrumb-item>
          <router-link :to="{ name: 'agent_monthly_bill_info', params: { agent_monthly_bill_id: billId }}">
            {{ agentSubscribeTrafficPoolOrder.month }}
          </router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item> {{ agentSubscribeTrafficPoolOrder.no }}</a-breadcrumb-item>
      </a-breadcrumb>

      <a-descriptions
        :column="3"
        class="simiot-descriptions simiot-descriptions-max-10"
      >
        <a-descriptions-item label="订单批次">
          {{ agentSubscribeTrafficPoolOrder.no }}
        </a-descriptions-item>

        <a-descriptions-item label="操作人">
          {{ agentSubscribeTrafficPoolOrder.operator_name }}
        </a-descriptions-item>

        <a-descriptions-item label="订单时间">
          {{ agentSubscribeTrafficPoolOrder.created_at }}
        </a-descriptions-item>

        <a-descriptions-item label="流量池名称">
          {{ agentSubscribeTrafficPoolOrder.package_name }}
        </a-descriptions-item>

        <a-descriptions-item label="周期数">
          {{ agentSubscribeTrafficPoolOrder.cycles }}
        </a-descriptions-item>

        <a-descriptions-item label="订单金额(元)">
          {{ agentSubscribeTrafficPoolOrder.total_fee | formatCurrency }}
        </a-descriptions-item>
      </a-descriptions>
    </a-spin>
  </div>
</template>

<script>
import { findAgentSubscribeTrafficPoolOrder } from '@/api/agent_subscribe_traffic_pool_order'

export default {
  name: 'ShowAgentSubscribeTrafficPoolOrder',
  data() {
    return {
      agentSubscribeTrafficPoolOrder: {},
      loading: true,
      loadingSimCardSubscribeTrafficPoolOrders: true,
      pagination: {
        total_count: 0
      },
      query: {},
      sort: {
        sort_field: '',
        sort_order: ''
      },
      data: []
    }
  },
  computed: {
    agentSubscribeTrafficPoolOrderId() {
      return parseInt(this.$route.params.subscribe_traffic_pool_order_id)
    },

    billId() {
      return parseInt(this.$route.query.bill_id)
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      findAgentSubscribeTrafficPoolOrder(this.agentSubscribeTrafficPoolOrderId).then((res) => {
        if (res.code === 0) {
          this.agentSubscribeTrafficPoolOrder = res.data
        }
        this.loading = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
.bread-crumb {
  margin-bottom: 20px;
}
</style>
